*,
*::before,
::after {
	text-rendering: geometricPrecision;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	font-size: 62.5%;
	scroll-behavior: smooth;
	scroll-padding-top: 7.8rem;
}

body {
	position: relative;

	min-height: 100vh;
	font-family: 'Montserrat', sans-serif;
	background-color: #fff;
	background: linear-gradient(
		92deg,
		rgba(219, 222, 224, 0.7) 0%,
		var(--color-background-light) 40%,
		var(--color-background-light) 60%,
		rgba(219, 222, 224, 0.7) 100%
	);
}

::selection {
	background-color: var(--color-resq-dark);
	color: var(--color-bs-bg);
}

.no-mb {
	margin-bottom: 0;
}
.darkest-bg {
	background-color: var(--color-resq-darkest);
}

.text-fat-red {
	color: var(--color-resq-red);
	font-weight: 800;
}

p,
li {
	font-size: 1.4rem;
	line-height: 1.6;
	color: var(--color-resq-dark);

	@include media(md) {
		font-size: 1.5rem;
	}
	@include media(lg) {
		font-size: 1.6rem;
	}
}

p {
	margin-bottom: 1em;
}
.text-center {
	text-align: center;
}
ul {
	margin-bottom: 1em;
	margin-left: 3.6em;
	// list-style-position: inside;
	list-style-type: '◼︎ ';
}

p.quote {
	position: relative;
	margin-left: 4em;
	margin-right: 4em;
	font-weight: 600;
	color: var(--color-resq-basic);
	&::before,
	&::after {
		position: absolute;

		font-size: 8em;
		font-weight: 400;
		content: '”';
	}
	&::before {
		left: -0.5em;
		top: -0.4em;
	}
	&::after {
		right: -0.5em;
		top: -0.4em;
	}
}
h1 {
	padding: 4.8rem 0;
	font-weight: 800;
	font-size: 4rem;
	color: var(--color-resq-basic);

	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;

	@include media(sm) {
		font-size: 5.6rem;
	}
	@include media(lg) {
		font-size: 6.4rem;
	}
}

h2 {
	font-weight: 700;
	text-transform: uppercase;
	color: var(--color-resq-darkest);
	// margin: 1.6rem 0;

	font-size: 2.4rem;
	@include media(lg) {
		font-size: 3.6rem;
		// margin: 0 0 32px;
	}
}

h3 {
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.4;
	margin-bottom: 0.3em;
	margin-top: 0.6em;
	@include media(sm) {
		font-size: 1.8rem;
	}
}
