.vehicles-in-offer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: var(--column-gap);
	@include media(md) {
		grid-template-columns: repeat(3, 1fr);
	}
	@include media(lg){
		grid-template-columns: repeat(4, 1fr);

	}
}
.vehicle-viewer {
	display: flex;
	flex-direction: column;

	h3 {
		padding: 0.3em 0.6em;
		font-size: 300%;
		color: var(--color-bs-bg);
		background-color: var(--color-resq-darkest);
	}

	h4 {
		font-size: 240%;
		color: var(--color-resq-basic);
	}
	.vehicle-display {
		grid-column: 1/-1;
		@include media(md) {
			grid-column: 1/10;
		}
		img {
			width: 100%;
		}
	}
	.vehicle-chooser {
		grid-column: 1/-1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 100%;

		@include media(md) {
			grid-column: 11/13;
			flex-direction: column;
			justify-content: space-around;
		}

		img {
			max-width: 20%;
			height: auto;
			transition: transform 0.1s;
			&:hover {
				transform: scale(1.05);
			}
			cursor: pointer;
			@include media(md) {
				max-width: 100%;
			}
		}
	}
}

.vehicle-card {
	display: flex;
	flex-direction: column;

	justify-content: space-between;

	background-image: linear-gradient(
		35deg,
		transparent 70%,
		hsla(213, 11%, 85%, 0.603)
	);

	background-image: linear-gradient(
		150deg,
		var(--color-bs-bg),
		var(--color-resq-background)
	);

	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.1s;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
	}

	&__title {
		margin-top: 0.6rem;
		padding: 0.6rem 1.2rem;
		background-color: var(--color-resq-darkest);

		font-size: 1.2rem;
		text-align: center;
		color: var(--color-bs-bg);

		@include media(sm) {
			font-size: 1.6rem;
			text-align: left;
		}
		@include media(md) {
			font-size: 1.8rem;
		}
		@include media(lg) {
			margin-top: 1.2rem;
		}
	}
	&__image {
		padding: 1.2rem;
		width: 100%;
		height: auto;
		object-fit: contain;
		@include media(lg) {
			padding: 2.4rem;
		}
	}
	&--disabled {
		cursor: default;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.025);
		display: none;
		@include media(lg) {
			display: flex;
		}
		&:hover {
			box-shadow: 0 0 30px rgba(0, 0, 0, 0.025);
		}
		.vehicle-card__title {
			background-color: var(--color-resq-lightest);
		}
	}
}

.offer-block {
	grid-column: 1/13;
	@include media(lg) {
		grid-column: 9/13;
	}
	@include media(lg) {
		grid-column: 10/13;
	}

	.text-block {
		grid-column: 1/13;
		@include media(lg) {
			grid-column: 1/9;
		}
	}

	.photo-box {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		row-gap: calc(var(--row-gap) / 2);
		column-gap: calc(var(--row-gap) / 2);
		margin-top: calc(var(--row-gap) / 2);
		margin-bottom: calc(var(--row-gap) / 2);

		// grid-column: 1/-1;
		@include media(lg) {
			flex-direction: column;
		}
		@include media(xl) {
			justify-content: space-between;
			align-items: center;
			// grid-column: 9/12;
			flex-wrap: wrap;
		}
		@include media(xxl) {
			// grid-column: 10/13;
		}

		&__scroll-down {
			display: flex;
			justify-content: center;
			text-align: center;
			font-size: 1.6rem;
			color: var(--color-resq-red);
			p {
				font-size: 0.6em;
				text-transform: uppercase;
				color: inherit;
				margin-bottom: 0;
				margin-top: 1em;
			}
			a {
				text-decoration: none;
				font-size: 3rem;
				color: inherit;
			}
		}
		a{
			text-decoration: none;
		}
	}
}

.table-block {
	display: grid;
	grid-template-columns: 1fr;
	column-gap: var(--column-gap);
	row-gap: var(--column-gap);

	@include media(md) {
		grid-template-columns: repeat(2, 1fr);
	}

	table {
width: 100%;		align-self: start;
		font-size: 1.3rem;
		line-height: 1.2;
		border-collapse: collapse;
		text-align: left;

		th,
		td {
			padding: 0.5em;
			vertical-align: top;
			border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		}

		tbody {
			tr {
				&:nth-child(odd) {
					background-color: rgba(0, 0, 0, 0.04);
				}
			}
		}
		@include media(md) {
			font-size: 1.6rem;
		}
	}
}
