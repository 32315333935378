.wrong-way {
	position: relative;
	.container {
		height: 50rem;
        max-width: 100%;
		background-image: url(../img/jannes-jacobs-VEH_xb3IIqQ-unsplash.jpg);
		background-size: cover;
		background-position: center;
        @include media(lg){
            height: 80rem;
        }
        @include media(xl){
            height: 100rem;
        }
	}
	&__text-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 60%;
		margin-left: auto;
		margin-right: auto;
		p {
			display: block;
			font-size:2rem;
			text-align: center;
			font-weight: 700;
            letter-spacing: .03em;
			margin-bottom: 1em;
			margin-top: 1em;
			text-shadow: 0 0 3px #000, 0 0 1.5rem rgba(16, 51, 61, 0.7);
			color: var(--color-bs-bg);

            @include media(lg){
                font-size: 3rem;
            }
		}
	}
}
