@charset "UTF-8";
:root {
  --color-bs-bg: rgb(248, 249, 250);
  --color-background: rgba(219, 222, 224, 0.3);
  --color-background-light: rgb(255, 255, 255);
  --color-resq-red: #e3000b;
  --color-resq-darkest: #0f1d2e;
  --color-resq-dark: #1b293b;
  --color-resq-basic: #415877;
  --color-resq-light: #778da9;
  --color-resq-lightest: #d3d7dc;
  --color-resq-orange: #ffbb1e;
  --color-resq-orange-dark: #cd9000;
  --section-padding: 4.8rem;
  --row-gap: var(--section-padding);
  --column-gap: 2.4rem;
}

*,
*::before,
::after {
  text-rendering: geometricPrecision;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 7.8rem;
}

body {
  position: relative;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  background: linear-gradient(92deg, rgba(219, 222, 224, 0.7) 0%, var(--color-background-light) 40%, var(--color-background-light) 60%, rgba(219, 222, 224, 0.7) 100%);
}

::selection {
  background-color: var(--color-resq-dark);
  color: var(--color-bs-bg);
}

.no-mb {
  margin-bottom: 0;
}

.darkest-bg {
  background-color: var(--color-resq-darkest);
}

.text-fat-red {
  color: var(--color-resq-red);
  font-weight: 800;
}

p,
li {
  font-size: 1.4rem;
  line-height: 1.6;
  color: var(--color-resq-dark);
}
@media (min-width: 768px) {
  p,
  li {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  p,
  li {
    font-size: 1.6rem;
  }
}

p {
  margin-bottom: 1em;
}

.text-center {
  text-align: center;
}

ul {
  margin-bottom: 1em;
  margin-left: 3.6em;
  list-style-type: "◼︎ ";
}

p.quote {
  position: relative;
  margin-left: 4em;
  margin-right: 4em;
  font-weight: 600;
  color: var(--color-resq-basic);
}
p.quote::before, p.quote::after {
  position: absolute;
  font-size: 8em;
  font-weight: 400;
  content: "”";
}
p.quote::before {
  left: -0.5em;
  top: -0.4em;
}
p.quote::after {
  right: -0.5em;
  top: -0.4em;
}

h1 {
  padding: 4.8rem 0;
  font-weight: 800;
  font-size: 4rem;
  color: var(--color-resq-basic);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (min-width: 576px) {
  h1 {
    font-size: 5.6rem;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 6.4rem;
  }
}

h2 {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--color-resq-darkest);
  font-size: 2.4rem;
}
@media (min-width: 992px) {
  h2 {
    font-size: 3.6rem;
  }
}

h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 0.3em;
  margin-top: 0.6em;
}
@media (min-width: 576px) {
  h3 {
    font-size: 1.8rem;
  }
}

.navbar {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  z-index: 100;
  background-color: rgba(248, 249, 250, 0.85);
  backdrop-filter: blur(6px);
}
@media (min-width: 992px) {
  .navbar {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
.navbar__container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 1.2rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.navbar__brand {
  display: flex;
  align-content: center;
}
.navbar__toggler {
  border: none;
  padding: 0.5rem 1rem;
  color: var(--color-resq-darkest);
  background: none;
}
@media (min-width: 992px) {
  .navbar__toggler {
    display: none;
  }
}
.navbar__toggler .fas {
  font-size: 3.2rem;
}
.navbar__links {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
@media (min-width: 992px) {
  .navbar__links {
    opacity: 1;
    width: auto;
    max-height: 100%;
    display: flex;
    flex-direction: row;
  }
}
.navbar .visible {
  opacity: 1;
  max-height: 500px;
}
.navbar__link-item {
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-resq-light);
  letter-spacing: 0.7px;
  text-align: center;
  padding: 1.8rem;
  text-decoration: none;
}
@media (min-width: 992px) {
  .navbar__link-item {
    font-size: 1.4rem;
    padding: 1rem 2rem;
    letter-spacing: 0;
    width: min-content;
    align-self: stretch;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .navbar__link-item {
    letter-spacing: 0.7px;
    padding: 1.2rem 2.4rem;
  }
}
.navbar__link-item:first-child {
  padding-top: 2.4em;
}
@media (min-width: 992px) {
  .navbar__link-item:first-child {
    padding-top: 1rem;
  }
}
@media (min-width: 992px) {
  .navbar__link-item:last-child {
    padding-right: 0;
  }
}
.navbar__link-item:hover {
  color: var(--color-resq-darkest);
}
.navbar__link-item--hot {
  background-color: var(--color-resq-red);
  color: white;
}
.navbar__link-item--hot:hover {
  background-color: var(--color-resq-basic);
  color: white;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
}
.navbar__link-item.active {
  color: var(--color-resq-darkest);
  font-weight: 700;
}

.navbar-toggler {
  border: none;
  color: var(--color-resq-darkest);
  font-size: 3.2rem;
}

.navbar-collapse {
  background-color: rgba(var(--color-background), 0.5);
  padding-top: 10px;
}
@media (min-width: 992px) {
  .navbar-collapse {
    background: none;
    height: auto;
    padding-top: 0;
  }
}

.services-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .services-nav {
    flex-direction: row;
  }
}
.services-nav a {
  color: var(--color-resq-light);
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 4px 12px;
}
@media (min-width: 992px) {
  .services-nav a {
    padding: 8px 16px;
  }
}
.services-nav a:hover {
  background-color: var(--color-resq-lightest);
}

.carousel {
  position: relative;
}
.carousel__inner {
  position: relative;
  height: 100%;
  height: 20rem;
  height: 70vw;
  overflow: hidden;
}
@media (min-width: 576px) {
  .carousel__inner {
    height: 60vw;
  }
}
@media (min-width: 768px) {
  .carousel__inner {
    height: 50vw;
  }
}
@media (min-width: 1200px) {
  .carousel__inner {
    height: 60rem;
  }
}
.carousel__item {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;
}
.carousel__item::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-image: linear-gradient(90deg, rgba(15, 29, 46, 0.95) 0%, rgba(15, 29, 46, 0.2) 100%);
  z-index: 5;
}
.carousel .active {
  visibility: visible;
  opacity: 1;
}
.carousel__image {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.carousel__caption {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 132rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5% 8%;
  color: #fff;
}
.carousel__caption h5.caption {
  font-weight: 800;
  text-transform: uppercase;
  text-align: left;
  z-index: 15;
  margin: 0;
  font-size: 5.2vw;
  text-shadow: 0 0 24px var(--color-resq-darkest), 0 0 8px var(--color-resq-darkest);
}
@media (min-width: 576px) {
  .carousel__caption h5.caption {
    font-size: 4.2vw;
  }
}
@media (min-width: 1200px) {
  .carousel__caption h5.caption {
    font-size: 54px;
  }
}
.carousel__caption ul {
  font-weight: 700;
  z-index: 15;
  list-style-position: inside;
  margin: 0;
}
.carousel__caption ul li {
  font-size: 3.6vw;
  text-align: left;
  color: #fff;
  text-shadow: 0 0 12px var(--color-resq-darkest), 0 0 4px var(--color-resq-darkest);
}
@media (min-width: 992px) {
  .carousel__caption ul li {
    font-size: 3.6rem;
  }
}
.carousel__caption .cta-services {
  font-size: 3vw;
  text-decoration: none;
  color: var(--color-resq-darkest);
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.5em 1em;
  margin-left: auto;
  margin-right: auto;
  z-index: 15;
  background-color: var(--color-resq-orange);
  transition: color 0.3s, background-color 0.3s;
}
@media (min-width: 576px) {
  .carousel__caption .cta-services {
    letter-spacing: 1.5px;
  }
}
@media (min-width: 1200px) {
  .carousel__caption .cta-services {
    font-size: 3.6rem;
  }
}
.carousel__caption .cta-services:hover {
  background-color: var(--color-resq-darkest);
  color: var(--color-resq-orange);
}
.carousel .carousel-indicators,
.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  z-index: 200;
}
.carousel .carousel-indicators {
  display: none;
}
.main-footer {
  text-align: center;
  position: sticky;
  top: 100%;
}
@media (min-width: 1400px) {
  .main-footer {
    text-align: left;
  }
}
.main-footer__grid {
  display: grid;
  row-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
}
@media (min-width: 375px) {
  .main-footer__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .main-footer__grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
}
.main-footer__logo {
  align-self: center;
}
.main-footer__address {
  grid-row: 2/3;
}
@media (min-width: 375px) {
  .main-footer__address {
    grid-row: 2/3;
  }
}
@media (min-width: 768px) {
  .main-footer__address {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}
.main-footer__info {
  grid-row: 3/4;
}
@media (min-width: 375px) {
  .main-footer__info {
    grid-row: 2/3;
  }
}
@media (min-width: 768px) {
  .main-footer__info {
    grid-column: 3/4;
    grid-row: 1/2;
  }
}
.main-footer__info p {
  text-decoration: none;
}
.main-footer__icons {
  align-self: center;
  grid-column: 1/2;
  grid-row: 4/5;
}
@media (min-width: 375px) {
  .main-footer__icons {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media (min-width: 768px) {
  .main-footer__icons {
    grid-column: 4/5;
  }
}
.main-footer img {
  height: 32px;
}
@media (min-width: 992px) {
  .main-footer img {
    height: 3.6rem;
  }
}
.main-footer p {
  color: var(--color-bs-bg);
  letter-spacing: 0.7px;
}
.main-footer i {
  color: var(--color-bs-bg);
  margin-right: 0.3em;
  font-size: 36px;
}
@media (min-width: 992px) {
  .main-footer i {
    font-size: 4rem;
  }
}

.wraper {
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
@media (min-width: 576px) {
  .container {
    width: 54rem;
  }
}
@media (min-width: 768px) {
  .container {
    width: 72rem;
  }
}
@media (min-width: 992px) {
  .container {
    width: 96rem;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 114rem;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 132rem;
  }
}

.extra-padding {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
}

.alternate-bg {
  background-color: var(--color-background);
}

.main-grid-box {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--column-gap);
  row-gap: var(--section-padding);
}
.main-grid-box h2 {
  grid-column: 1/13;
}
.main-grid-box .text-block {
  grid-column: 1/13;
}
@media (min-width: 992px) {
  .main-grid-box .text-block {
    grid-column: 1/9;
  }
}
.main-grid-box .hero-image {
  grid-column: 1/13;
  grid-row: 2/3;
  width: 100%;
}
@media (min-width: 992px) {
  .main-grid-box .hero-image {
    grid-column: 9/13;
  }
}

.grid-full-width {
  grid-column: 1/-1 !important;
}

.col-2-grid-box {
  display: grid;
  column-gap: calc(var(--column-gap) * 2);
}
@media (min-width: 1200px) {
  .col-2-grid-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*
main.services section, main.offer section,
main.work section {

	.container-fluid {
		padding-top: 24px;
		padding-bottom: 24px;
		@include media(lg) {
			padding: 64px 0;
		}
	}

	.row:first-child {
		@include media(lg) {
			margin-bottom: 62px;
		}
	}
}

main.services h2 {
	order: 1;
}
main.services .text-block {
	order: 3;
	@include media(lg) {
		order: 2;
	}
}
.lg-gap {
	order: 3;
}
main.services [class$='-img'],
main.work [class$='-img'] {
	order: 2;
	@include media(lg) {
		order: 4;
	}
}
*/
.offer__grid-box {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--column-gap);
  row-gap: var(--section-padding);
}
.offer__grid-box h2 {
  grid-column: 1/-1;
}
.offer__grid-box h3 {
  grid-column: 1/-1;
}
.offer__cards {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: var(--column-gap);
  row-gap: var(--row-gap);
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .offer__cards {
    flex-wrap: nowrap;
  }
}
.offer__card {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, background-color 0.3s;
  width: 100%;
}
@media (min-width: 375px) {
  .offer__card {
    width: 35rem;
  }
}
@media (min-width: 768px) {
  .offer__card {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .offer__card {
    width: calc((100% - 11 * (var(--column-gap))) / 12 * 4);
  }
}
.offer__card:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.3);
}
.offer .inline-link {
  color: inherit;
  font-weight: 700;
  transition: background-color 0.3s, color 0.3s;
}
.offer .inline-link:hover {
  color: var(--color-bs-bg);
  background-color: var(--color-resq-basic);
}
.offer .cta-box {
  grid-column: 1/-1;
  padding-top: var(--section-padding);
}
.offer__offer {
  grid-column: 1/13;
}
@media (min-width: 992px) {
  .offer__offer {
    grid-column: 9/13;
  }
}
@media (min-width: 992px) {
  .offer__offer {
    grid-column: 10/13;
  }
}

.options-heading {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-resq-basic);
}
@media (min-width: 768px) {
  .options-heading {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .options-heading {
    font-size: 30px;
  }
}

.options-table {
  text-align: center;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: var(--color-background);
}
.options-table__head {
  padding: 2.4rem 1.2rem;
  background-color: var(--color-resq-basic);
}
.options-table__head p {
  color: white;
}
.options-table__row {
  padding: 0.6rem;
  position: relative;
}
.options-table__row:not(.last-row)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  translate: -50%;
  height: 1px;
  width: 100%;
  background-color: var(--color-resq-darkest);
}
@media (min-width: 768px) {
  .options-table__row {
    padding: 1.2rem;
  }
}
.options-table__row--highlighted {
  background-color: var(--color-bs-bg);
  background-color: var(--color-resq-lightest);
}
.options-table__row--accent {
  background-color: var(--color-resq-red);
}
.options-table__row--accent p {
  color: var(--color-bs-bg);
}
.options-table h4 {
  font-size: 2rem;
  font-weight: 800;
  margin-top: 0.5em;
  color: white;
}
@media (min-width: 992px) {
  .options-table h4 {
    font-size: 2.4rem;
  }
}
.options-table p {
  font-size: 1.4rem;
  line-height: 1.3;
  margin: 0;
}
@media (min-width: 992px) {
  .options-table p {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}
.options-table .label {
  font-size: 1.3rem;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0px auto 0px;
}
@media (min-width: 768px) {
  .options-table .label {
    font-size: 1.5rem;
  }
}
.options-table .amount {
  font-size: 200%;
  font-weight: 800;
}

.railway-img {
  background-image: url(../img/jason-thompson-KMTRh3xcH1A-unsplash-1280.jpg);
  background-size: cover;
  height: 50vw;
  margin: 0 auto 16px auto;
  background-position: 50% 40%;
}
@media (min-width: 992px) {
  .railway-img {
    min-height: 300px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .railway-img {
    background-position: 70% 0%;
  }
}

.city-img {
  background-image: url(../img/EOS-R-283028001768-2022-10-18_MG_7635-1280.jpg);
  background-size: cover;
  height: 50vw;
  margin: 0 auto 16px auto;
  background-position: 50% 85%;
}
@media (min-width: 992px) {
  .city-img {
    min-height: 300px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .city-img {
    background-position: 55% 0%;
  }
}

.port-img {
  background-image: url(../img/david-vives-zLcV9nXr2y0-unsplash-1280.jpg);
  background-size: cover;
  height: 50vw;
  margin: 0 auto 16px auto;
  background-position: 50% 60%;
}
@media (min-width: 992px) {
  .port-img {
    min-height: 300px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .port-img {
    background-position: 50% 0%;
  }
}

.hr-img {
  background-image: url(../img/benjamin-wedemeyer-bzzSg8hTKKY-unsplash-1280.jpg);
  background-size: cover;
  height: 50vw;
  margin: 0 auto 16px auto;
  background-position: 50% 75%;
}
@media (min-width: 992px) {
  .hr-img {
    min-height: 300px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .hr-img {
    background-position: 70% 0%;
  }
}

.vehicle-img {
  background-image: url(../img/Vehicle-Volvo1-bg.jpg);
  background-size: cover;
  height: 50vw;
  margin: 0 auto 16px auto;
  background-position: 50% 70%;
}
@media (min-width: 992px) {
  .vehicle-img {
    min-height: 300px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .vehicle-img {
    background-position: 5% 0%;
  }
}

.work-img {
  background-image: url(../img/mads-eneqvist-J9jYy9S1zAk-unsplash-1280.jpg);
  background-size: cover;
  height: 50vw;
  margin: 0 auto 16px auto;
  background-position: 50% 50%;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .work-img {
    min-height: 300px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .work-img {
    background-position: 20% 0%;
  }
}

.contact__btns {
  display: flex;
  justify-content: space-around;
}
.contact__btn {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  height: 100%;
}
.contact__btn i {
  font-size: 70px;
  color: white;
  line-height: 0.9;
  margin: 0 auto 4px;
  padding: 24px;
  background-color: var(--color-resq-red);
  transition: background-color 0.2s;
}
@media (min-width: 768px) {
  .contact__btn i {
    margin-bottom: 16px;
  }
}
.contact__btn i:hover {
  background-color: var(--color-resq-basic);
  color: white;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
}

.offer-link {
  text-decoration: none;
  color: inherit;
}
.offer-link:hover {
  color: inherit;
}

.aboutus__offer {
  grid-column: 1/13;
}
@media (min-width: 992px) {
  .aboutus__offer {
    grid-column: 9/13;
  }
}
@media (min-width: 992px) {
  .aboutus__offer {
    grid-column: 10/13;
  }
}
.aboutus .card {
  background: none;
  box-shadow: none;
  text-align: center;
}
.aboutus h3 {
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  color: var(--color-resq-basic);
  margin-top: 0;
}
@media (min-width: 992px) {
  .aboutus .call-now-btn p {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .aboutus .call-now-btn i {
    font-size: 36px;
  }
}

.cta-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta--desktop {
  display: none;
}
@media (min-width: 768px) {
  .cta--desktop {
    display: block;
  }
}
.cta--mobile {
  display: block;
}
@media (min-width: 768px) {
  .cta--mobile {
    display: none;
  }
}
.cta__btn {
  display: flex;
  align-items: stretch;
  width: max-content;
  column-gap: 1rem;
  padding: 1.5em 1.8em;
  color: white;
  background-color: var(--color-resq-red);
  font-size: 1rem;
  font-weight: 800;
  text-decoration: none;
  transition: background-color 0.2s, transform 0.2s;
}
@media (min-width: 768px) {
  .cta__btn {
    align-items: center;
    padding: 1.5em 3em;
  }
}
.cta__btn:hover {
  background-color: var(--color-resq-basic);
  color: white;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  transform: scale(1.01, 1.03);
}
.cta__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cta__text p {
  font-size: 1.8em;
  line-height: 1;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: inherit;
  margin: 0;
}
@media (min-width: 768px) {
  .cta__text p {
    font-size: 2em;
  }
}
@media (min-width: 992px) {
  .cta__text p {
    font-size: 2.4em;
  }
}
.cta__text p.call-now-number {
  font-size: 1.2em;
  font-weight: 400;
}
.cta i {
  font-size: 3.6em;
  line-height: 0.9;
}
@media (min-width: 768px) {
  .cta i {
    font-size: 3em;
  }
}
@media (min-width: 992px) {
  .cta i {
    font-size: 3.6em;
  }
}

.cta-btn .fa-chevron-right {
  font-size: 2em;
}
@media (min-width: 768px) {
  .cta-btn .fa-chevron-right {
    font-size: 3em;
  }
}

.vehicles-in-offer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--column-gap);
}
@media (min-width: 768px) {
  .vehicles-in-offer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .vehicles-in-offer {
    grid-template-columns: repeat(4, 1fr);
  }
}

.vehicle-viewer {
  display: flex;
  flex-direction: column;
}
.vehicle-viewer h3 {
  padding: 0.3em 0.6em;
  font-size: 300%;
  color: var(--color-bs-bg);
  background-color: var(--color-resq-darkest);
}
.vehicle-viewer h4 {
  font-size: 240%;
  color: var(--color-resq-basic);
}
.vehicle-viewer .vehicle-display {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .vehicle-viewer .vehicle-display {
    grid-column: 1/10;
  }
}
.vehicle-viewer .vehicle-display img {
  width: 100%;
}
.vehicle-viewer .vehicle-chooser {
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
@media (min-width: 768px) {
  .vehicle-viewer .vehicle-chooser {
    grid-column: 11/13;
    flex-direction: column;
    justify-content: space-around;
  }
}
.vehicle-viewer .vehicle-chooser img {
  max-width: 20%;
  height: auto;
  transition: transform 0.1s;
  cursor: pointer;
}
.vehicle-viewer .vehicle-chooser img:hover {
  transform: scale(1.05);
}
@media (min-width: 768px) {
  .vehicle-viewer .vehicle-chooser img {
    max-width: 100%;
  }
}

.vehicle-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(35deg, transparent 70%, hsla(213deg, 11%, 85%, 0.603));
  background-image: linear-gradient(150deg, var(--color-bs-bg), var(--color-resq-background));
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s;
  cursor: pointer;
}
.vehicle-card:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.vehicle-card__title {
  margin-top: 0.6rem;
  padding: 0.6rem 1.2rem;
  background-color: var(--color-resq-darkest);
  font-size: 1.2rem;
  text-align: center;
  color: var(--color-bs-bg);
}
@media (min-width: 576px) {
  .vehicle-card__title {
    font-size: 1.6rem;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .vehicle-card__title {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .vehicle-card__title {
    margin-top: 1.2rem;
  }
}
.vehicle-card__image {
  padding: 1.2rem;
  width: 100%;
  height: auto;
  object-fit: contain;
}
@media (min-width: 992px) {
  .vehicle-card__image {
    padding: 2.4rem;
  }
}
.vehicle-card--disabled {
  cursor: default;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.025);
  display: none;
}
@media (min-width: 992px) {
  .vehicle-card--disabled {
    display: flex;
  }
}
.vehicle-card--disabled:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.025);
}
.vehicle-card--disabled .vehicle-card__title {
  background-color: var(--color-resq-lightest);
}

.offer-block {
  grid-column: 1/13;
}
@media (min-width: 992px) {
  .offer-block {
    grid-column: 9/13;
  }
}
@media (min-width: 992px) {
  .offer-block {
    grid-column: 10/13;
  }
}
.offer-block .text-block {
  grid-column: 1/13;
}
@media (min-width: 992px) {
  .offer-block .text-block {
    grid-column: 1/9;
  }
}
.offer-block .photo-box {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  row-gap: calc(var(--row-gap) / 2);
  column-gap: calc(var(--row-gap) / 2);
  margin-top: calc(var(--row-gap) / 2);
  margin-bottom: calc(var(--row-gap) / 2);
}
@media (min-width: 992px) {
  .offer-block .photo-box {
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .offer-block .photo-box {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
.offer-block .photo-box__scroll-down {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.6rem;
  color: var(--color-resq-red);
}
.offer-block .photo-box__scroll-down p {
  font-size: 0.6em;
  text-transform: uppercase;
  color: inherit;
  margin-bottom: 0;
  margin-top: 1em;
}
.offer-block .photo-box__scroll-down a {
  text-decoration: none;
  font-size: 3rem;
  color: inherit;
}
.offer-block .photo-box a {
  text-decoration: none;
}

.table-block {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--column-gap);
  row-gap: var(--column-gap);
}
@media (min-width: 768px) {
  .table-block {
    grid-template-columns: repeat(2, 1fr);
  }
}
.table-block table {
  width: 100%;
  align-self: start;
  font-size: 1.3rem;
  line-height: 1.2;
  border-collapse: collapse;
  text-align: left;
}
.table-block table th,
.table-block table td {
  padding: 0.5em;
  vertical-align: top;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.table-block table tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (min-width: 768px) {
  .table-block table {
    font-size: 1.6rem;
  }
}

.cookie {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  padding: 2rem;
  background-color: rgba(248, 249, 250, 0.85);
  backdrop-filter: blur(6px);
  box-shadow: 0 0 18rem rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translateY(100%);
  animation: show-cookies 0.5s 0.5s forwards;
}
@media (min-width: 768px) {
  .cookie {
    left: initial;
    padding: 4rem;
    width: 50rem;
    border-top-left-radius: 6rem;
  }
}
.cookie p {
  text-align: center;
}
.cookie .accept-cookie {
  background-color: var(--color-resq-red);
  border: none;
  padding: 0.6em 1.2em;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  transition: background-color 0.2s, transform 0.2s;
  width: max-content;
  cursor: pointer;
}
@media (min-width: 768px) {
  .cookie .accept-cookie {
    font-size: 2.4rem;
  }
}
.cookie .accept-cookie:focus {
  outline: 1px solid black;
}
.cookie .accept-cookie:hover {
  background-color: var(--color-resq-basic);
  color: white;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  transform: scale(1.01, 1.03);
}
.cookie::before {
  position: absolute;
  text-align: center;
  top: -7rem;
  width: 8rem;
  height: 8rem;
  content: "";
  background-image: url(../img/cookie.png);
  background-size: cover;
}
@media (min-width: 768px) {
  .cookie::before {
    left: -6rem;
    top: -14rem;
    width: 18rem;
    height: 18rem;
  }
}
.cookie__hide {
  display: none;
}

@keyframes show-cookies {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.wrong-way {
  position: relative;
}
.wrong-way .container {
  height: 50rem;
  max-width: 100%;
  background-image: url(../img/jannes-jacobs-VEH_xb3IIqQ-unsplash.jpg);
  background-size: cover;
  background-position: center;
}
@media (min-width: 992px) {
  .wrong-way .container {
    height: 80rem;
  }
}
@media (min-width: 1200px) {
  .wrong-way .container {
    height: 100rem;
  }
}
.wrong-way__text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.wrong-way__text-box p {
  display: block;
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.03em;
  margin-bottom: 1em;
  margin-top: 1em;
  text-shadow: 0 0 3px #000, 0 0 1.5rem rgba(16, 51, 61, 0.7);
  color: var(--color-bs-bg);
}
@media (min-width: 992px) {
  .wrong-way__text-box p {
    font-size: 3rem;
  }
}