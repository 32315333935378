.aboutus {

	&__offer{
		grid-column: 1/13;
		@include media(lg) {
			grid-column: 9/13;
		}
		@include media(lg) {
			grid-column: 10/13;
		}
	}
	.card {
		background: none;
		box-shadow: none;
		text-align: center;
	}

	h3 {
		text-transform: uppercase;
		text-align: center;
		width: 100%;
		color: var(--color-resq-basic);
		margin-top: 0;
	}

	.call-now-btn {
		p {
			@include media(md) {
			}
			@include media(lg) {
				font-size: 24px;
			}
		}
		i {
			@include media(lg) {
				font-size: 36px;
			}
		}
	}
	
}
