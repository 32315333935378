.offer {
	&__grid-box {
		@include grid-12-col;

		h2 {
			grid-column: 1/-1;
		}
		h3 {
			grid-column: 1/-1;
		}
	}

	&__cards {
		grid-column: 1/-1;

		display: flex;
		justify-content: center;
		align-items: flex-end;
		column-gap: var(--column-gap);
		row-gap: var(--row-gap);
		flex-wrap: wrap;

		@include media(md) {
			flex-wrap: nowrap;
		}
	}

	&__card {
		background: none;
		border: none;
		border-radius: 0;
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

		transition: box-shadow 0.3s, background-color 0.3s;
		width: 100%;
		@include media(xs) {
			width: 35rem;
		}

		@include media(md) {
			width: 100%;
		}
		@include media(xl) {
			width: calc(((100% - (11 * (var(--column-gap)))) / 12) * 4);
		}

		&:hover {
			box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(0, 0, 0, 0.2);
			background-color: rgba(255, 255, 255, 0.3);
		}
	}
	.inline-link {
		color: inherit;
		font-weight: 700;
		transition: background-color 0.3s, color 0.3s;
		&:hover {
			color: var(--color-bs-bg);
			background-color: var(--color-resq-basic);
		}
	}
	.cta-box {
		grid-column: 1/-1;
		padding-top: var(--section-padding);
	}
	&__offer{
		grid-column: 1/13;
		@include media(lg) {
			grid-column: 9/13;
		}
		@include media(lg) {
			grid-column: 10/13;
		}
	}

}

.options-heading {
	text-align: center;
	font-size: 20px;
	text-transform: uppercase;
	color: var(--color-resq-basic);

	@include media(md) {
		font-size: 24px;
	}

	@include media(lg) {
		font-size: 30px;
	}
}
.options-table {
	text-align: center;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
	background-color: var(--color-background);

	&__head {
		padding: 2.4rem 1.2rem;
		background-color: var(--color-resq-basic);
		p {
			color: white;
		}
	}
	&__row {
		padding: 0.6rem;
		position: relative;
		&:not(.last-row)::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			translate: -50%;
			height: 1px;
			width: 100%;
			background-color: var(--color-resq-darkest);
		}
		@include media(md) {
			padding: 1.2rem;
		}
		&--highlighted {
			background-color: var(--color-bs-bg);
			background-color: var(--color-resq-lightest);
		}
		&--accent {
			background-color: var(--color-resq-red);
			p {
				color: var(--color-bs-bg);
			}
		}
	}
	h4 {
		font-size: 2rem;
		font-weight: 800;
		margin-top: 0.5em;
		color: white;
		@include media(lg) {
			font-size: 2.4rem;
		}
	}
	p {
		font-size: 1.4rem;
		line-height: 1.3;
		margin: 0;
		@include media(lg) {
			font-size: 1.6rem;
			line-height: 1.6;
		}
	}

	.label {
		font-size: 1.3rem;
		font-weight: 800;
		text-transform: uppercase;
		margin: 0px auto 0px;
		@include media(md) {
			font-size: 1.5rem;
		}
	}

	.amount {
		font-size: 200%;
		font-weight: 800;
	}
}
