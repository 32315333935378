.main-footer {
	text-align: center;
	position: sticky;
	top: 100%;
	@include media(xxl) {
		text-align: left;
	}
	&__grid {
		display: grid;
		row-gap: 2rem;

		grid-template-columns: 1fr;
		grid-template-rows: repeat(4, 1fr);
		@include media(xs) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
		}

		@include media(md) {
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr;
		}
	}

	&__logo {
		align-self: center;
	}
	&__address {
		grid-row: 2/3;
		@include media(xs) {
			grid-row: 2/3;
		}
		@include media(md) {
			grid-row: 1/2;
			grid-column: 2/3;
		}
	}
	&__info {
		grid-row: 3/4;
		@include media(xs) {
			grid-row: 2/3;
		}
		@include media(md) {
			grid-column: 3/4;
			grid-row: 1/2;
		}
		p {
			text-decoration: none;
		}
	}
	&__icons {
		align-self: center;
		grid-column: 1/2;
		grid-row: 4/5;
		@include media(xs) {
			grid-column: 2/3;
			grid-row: 1/2;
		}
		@include media(md) {
			grid-column: 4/5;
		}
	}

	img {
		height: 32px;
		@include media(lg) {
			height: 3.6rem;
		}
	}
	p {
		color: var(--color-bs-bg);
		letter-spacing: 0.7px;
	}
	i {
		color: var(--color-bs-bg);
		margin-right: 0.3em;
		font-size: 36px;
		@include media(lg) {
			font-size: 4rem;
		}
	}
}
