.cta-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.cta {
	&--desktop {
		display: none;
		@include media(md) {
			display: block;
		}
	}
	&--mobile {
		display: block;
		@include media(md) {
			display: none;
		}
	}
	&__btn {
		display: flex;
		align-items: stretch;
		width: max-content;
		column-gap: 1rem;

		padding: 1.5em 1.8em;
		color: white;
		background-color: var(--color-resq-red);

		font-size: 1rem;
		font-weight: 800;
		text-decoration: none;

		transition: background-color 0.2s, transform 0.2s;

		@include media(md) {
			align-items: center;
			padding: 1.5em 3em;
		}

		&:hover {
			@include hover-cta;
			transform: scale(1.01, 1.03);
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;

		p {
			font-size: 1.8em;
			line-height: 1;
			font-weight: 800;
			letter-spacing: 1px;
			text-transform: uppercase;

			color: inherit;
			margin: 0;

			@include media(md) {
				font-size: 2em;
			}
			@include media(lg) {
				font-size: 2.4em;
			}
		}
		p.call-now-number {
			font-size: 1.2em;
			font-weight: 400;
		}
	}

	i {
		font-size: 3.6em;
		line-height: 0.9;
		@include media(md) {
			font-size: 3em;
		}
		@include media(lg) {
			font-size: 3.6em;
		}
	}
}

.cta-btn {
	.fa-chevron-right {
		font-size: 2em;
		// align-self: flex-end;
		@include media(md) {
			font-size: 3em;
		}
	}
}
