.contact {
	&__btns {
		display: flex;
		justify-content:space-around;
	}
	&__btn {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		text-align: center;
		text-decoration: none;
		
		height: 100%;
		i {
			font-size: 70px;
			color: white;
			line-height: 0.9;
			margin: 0 auto 4px;
			padding: 24px;
			background-color: var(--color-resq-red);

			@include media(md) {
				margin-bottom: 16px;
			}

			transition: background-color 0.2s;
			&:hover {
				@include hover-cta;
			}
		}
	}
}
