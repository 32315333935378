.cookie {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1000;

	padding: 2rem;
	background-color: rgba(248, 249, 250, 0.85);
	backdrop-filter: blur(6px);

	box-shadow: 0 0 18rem rgba(0, 0, 0, 0.3);

	opacity: 0;
	transform: translateY(100%);
	animation: show-cookies 0.5s 0.5s forwards;

	@include media(md) {
		left: initial;
		padding: 4rem;
		width: 50rem;
		// align-items: flex-end;
		border-top-left-radius: 6rem;
	}

	p {
		text-align: center;
	}

	.accept-cookie {
		background-color: var(--color-resq-red);
		border: none;

		padding: 0.6em 1.2em;
		font-family: inherit;
		font-size: 1.8rem;
		font-weight: 800;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-align: center;
		color: white;
		transition: background-color 0.2s, transform 0.2s;
		width: max-content;
		cursor: pointer;

		@include media(md) {
			font-size: 2.4rem;
		}

		&:focus {
			outline: 1px solid black;
		}
		&:hover {
			@include hover-cta;
			transform: scale(1.01, 1.03);
		}
	}

	&::before {
		position: absolute;
		text-align: center;
		top: -7rem;
		width: 8rem;
		height: 8rem;
		content: '';
		background-image: url(../img/cookie.png);
		background-size: cover;

		@include media(md) {
			left: -6rem;
			top: -14rem;
			width: 18rem;
			height: 18rem;
		}
	}
	&__hide {
		display: none;
	}
}

@keyframes show-cookies {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
