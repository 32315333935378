.navbar {
	position: sticky;
	top: 0;
	display: flex;
	flex-wrap: wrap;
	z-index: 100;
	background-color: rgba(248, 249, 250, 0.85);
	backdrop-filter: blur(6px);

	@include media(lg) {
		flex-wrap: nowrap;
		justify-content: center;
	}

	&__container {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;
		padding: 1.8rem 1.2rem;

		width: 100%;
		margin-left: auto;
		margin-right: auto;
		@include media(lg) {
		}
	}
	&__brand {
		display: flex;
		align-content: center;
	}
	&__toggler {
		border: none;
		padding: 0.5rem 1rem;
		color: var(--color-resq-darkest);
		background: none;
		@include media(lg) {
			display: none;
		}

		.fas {
			font-size: 3.2rem;
		}
	}

	&__links {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;

		overflow: hidden;

		opacity: 0;
		max-height: 0;

		transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;

		@include media(lg) {
			opacity: 1;
			width: auto;
			max-height: 100%;
			display: flex;
			flex-direction: row;
		}
	}
	.visible {
		opacity: 1;
		max-height: 500px;
	}
	&__link-item {
		font-size: 2rem;
		font-weight: 500;
		text-transform: uppercase;
		color: var(--color-resq-light);
		letter-spacing: 0.7px;
		text-align: center;

		padding: 1.8rem;
		text-decoration: none;
		// align-self: center;

		@include media(lg) {
			font-size: 1.4rem;
			padding: 1rem 2rem;
			letter-spacing: 0;
			width: min-content;
			align-self: stretch;
			display: flex;
			align-items: center;
		}
		@include media(xl) {
			letter-spacing: 0.7px;
			padding: 1.2rem 2.4rem;
		}
		&:first-child {
			padding-top: 2.4em;
			@include media(lg) {
				padding-top: 1rem;
			}
		}
		&:last-child {
			@include media(lg) {
				padding-right: 0;
			}
		}
		&:hover {
			color: var(--color-resq-darkest);
		}
		&--hot {
			background-color: var(--color-resq-red);
			color: white;
			&:hover {
				@include hover-cta;
			}
		}

		&.active {
			color: var(--color-resq-darkest);
			font-weight: 700;
		}
	}
}

.navbar-toggler {
	border: none;
	color: var(--color-resq-darkest);
	font-size: 3.2rem;
}

.navbar-collapse {
	background-color: rgba(var(--color-background), 0.5);
	padding-top: 10px;

	@include media(lg) {
		background: none;
		height: auto;
		padding-top: 0;
	}
}

.services-nav {
	display: flex;
	flex-wrap: wrap;
	// flex-direction: column;
	justify-content: center;
	align-items: center;

	@include media(lg) {
		flex-direction: row;
	}
	a {
		color: var(--color-resq-light);
		font-size: 1rem;
		text-decoration: none;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		padding: 4px 12px;
		@include media(lg) {
			padding: 8px 16px;
		}
		&:hover {
			background-color: var(--color-resq-lightest);
		}
	}
}
