.carousel {
	position: relative;

	&__inner {
		position: relative;
		// margin-top: 1.2rem;

		height: 100%;
		height: 20rem;
		height: 70vw;
		overflow: hidden;

		@include media(sm) {
			height: 60vw;
		}
		@include media(md) {
			height: 50vw;
		}
		@include media(xl) {
			height: 60rem;
		}
	}
	&__item {
		position: absolute;
		top: 0;

		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;

		visibility: hidden;
		opacity: 0;
		transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			background-image: linear-gradient(
				90deg,
				rgba(15, 29, 46, 0.95) 0%,
				rgba(15, 29, 46, 0.2) 100%
			);
			z-index: 5;
		}
	}
	.active {
		visibility: visible;
		opacity: 1;
	}

	&__image {
		min-width: 100%;
		min-height: 100%;
		object-fit: cover;
	}

	&__caption {
		position: absolute;
		top: 0;
		bottom: 0;

		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 132rem;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		padding: 5% 8%;
		color: #fff;

		h5.caption {
			font-weight: 800;
			text-transform: uppercase;
			text-align: left;

			z-index: 15;
			margin: 0;

			font-size: 5.2vw;
			text-shadow: 0 0 24px var(--color-resq-darkest),
				0 0 8px var(--color-resq-darkest);
			@include media(sm) {
				font-size: 4.2vw;
			}
			@include media(xl) {
				font-size: 54px;
			}
		}

		ul {
			font-weight: 700;
			z-index: 15;
			list-style-position: inside;
			margin: 0;

			li {
				font-size: 3.6vw;
				text-align: left;
				color: #fff;

				text-shadow: 0 0 12px var(--color-resq-darkest),
					0 0 4px var(--color-resq-darkest);

				// @include media(xs) {
				// 	font-size: 16px;
				// }
				@include media(sm) {
					// font-size: 2rem;
				}
				@include media(lg) {
					font-size: 3.6rem;
				}
			}
		}
		.cta-services {
			font-size: 3vw;
			text-decoration: none;
			color: var(--color-resq-darkest);
			text-transform: uppercase;
			font-weight: 700;

			padding: 0.5em 1em;
			margin-left: auto;
			margin-right: auto;
			z-index: 15;

			@include media(sm) {
				letter-spacing: 1.5px;
			}
			@include media(lg) {
			}
			@include media(xl) {
				font-size: 3.6rem;
			}

			background-color: var(--color-resq-orange);
			transition: color 0.3s, background-color 0.3s;
			&:hover {
				background-color: var(--color-resq-darkest);
				color: var(--color-resq-orange);
			}
		}
	}

	.carousel-indicators,
	.carousel-control-prev,
	.carousel-control-next {
		z-index: 200;
	}

	.carousel-indicators {
		display: none;
		@include media(lg) {
			// display: flex;
		}
	}
}
