:root {
	--color-bs-bg: rgb(248, 249, 250);
	--color-background: rgba(219, 222, 224, 0.3);
	--color-background-light: rgb(255, 255, 255);
	--color-resq-red: #e3000b;
	--color-resq-darkest: #0f1d2e;
	--color-resq-dark: #1b293b;
	--color-resq-basic: #415877;
	--color-resq-light: #778da9;
	--color-resq-lightest: #d3d7dc;
	--color-resq-orange: #ffbb1e;
	--color-resq-orange-dark: #cd9000;

	--section-padding: 4.8rem;
	--row-gap: var(--section-padding);
	--column-gap: 2.4rem;
}

@mixin media($breakpoint) {
	@if $breakpoint == xs {
		@media (min-width: 375px) {
			@content;
		}
	}
	@if $breakpoint == sm {
		@media (min-width: 576px) {
			@content;
		}
	}
	@if $breakpoint == md {
		@media (min-width: 768px) {
			@content;
		}
	}
	@if $breakpoint == lg {
		@media (min-width: 992px) {
			@content;
		}
	}
	@if $breakpoint == xl {
		@media (min-width: 1200px) {
			@content;
		}
	}
	@if $breakpoint == xxl {
		@media (min-width: 1400px) {
			@content;
		}
	}
}

@mixin hover-cta {
	background-color: var(--color-resq-basic);
	color: white;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
}

@mixin grid-12-col {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	column-gap: var(--column-gap);
	row-gap: var(--section-padding);
}
