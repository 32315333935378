.wraper {
	width: 100%;
}
.container {
	position: relative;
	width: 100%;
	height: 100%;

	margin-left: auto;
	margin-right: auto;
	padding-left: 1.2rem;
	padding-right: 1.2rem;

	@include media(sm) {
		width: 54rem;
	}
	@include media(md) {
		width: 72rem;
	}
	@include media(lg) {
		width: 96rem;
	}
	@include media(xl) {
		width: 114rem;
	}
	@include media(xxl) {
		width: 132rem;
	}
}
.extra-padding {
	padding-top: var(--section-padding);
	padding-bottom: var(--section-padding);
}
.alternate-bg {
	background-color: var(--color-background);
}

.main-grid-box {
	@include grid-12-col;
	h2 {
		grid-column: 1/13;
	}
	.text-block {
		grid-column: 1/13;
		@include media(lg) {
			grid-column: 1/9;
		}
	}
	.hero-image {
		grid-column: 1/13;
		grid-row: 2/3;
		width: 100%;
		@include media(lg) {
			grid-column: 9/13;
		}
	}
}

.grid-full-width {
	grid-column: 1/-1 !important;
}
.col-2-grid-box {
	display: grid;
	column-gap: calc(var(--column-gap) * 2);
	@include media(xl) {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*
main.services section, main.offer section,
main.work section {

	.container-fluid {
		padding-top: 24px;
		padding-bottom: 24px;
		@include media(lg) {
			padding: 64px 0;
		}
	}

	.row:first-child {
		@include media(lg) {
			margin-bottom: 62px;
		}
	}
}

main.services h2 {
	order: 1;
}
main.services .text-block {
	order: 3;
	@include media(lg) {
		order: 2;
	}
}
.lg-gap {
	order: 3;
}
main.services [class$='-img'],
main.work [class$='-img'] {
	order: 2;
	@include media(lg) {
		order: 4;
	}
}
*/
